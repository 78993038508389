import * as React from 'react';

import { motion } from 'framer-motion';
import {
    StaticQuery,
    graphql
} from 'gatsby';

import { Head as DefaultHead } from '../../components/head/head.component';
import { OurSkillsBlock } from '../../components/page-home/our-skills-block/our-skills-block.component';
import { ExpertiseCertificationBlock } from '../../components/shared/expertise-certification-block/expertise-certification-block.component';
import { PageTopBlock } from '../../components/shared/page-top-block/page-top-block.component';
import { seo } from '../../config/seo-config';
import {
    BannerProps,
    CtaProps,
    GatsbyStrapiPictureField,
    SimpleImageBlockProps
} from '../../models/cms-common';
import { isBrowser } from '../../utils';

interface NosOffresProps {
    ref: React.RefObject<HTMLDivElement>;
    pageTitle: string;
    pageSubtitle: string;

    imageBlocks: (SimpleImageBlockProps & CtaProps & { motionDesign: string; })[];

    cerTitle: string;
    cerSubtitle: string;
    certifications: {
        slug: string;
        CertificationBadge: {
            title: string;
            subtitle: string;
            description: string;
            ctaText: string;
            ctaLink: string;
            picture: GatsbyStrapiPictureField;
        }
    }[];
}

const NosOffresEnhancer = (Component: React.ComponentType<NosOffresProps>) => {
    return (
        <StaticQuery<{ strapiNosOffres: NosOffresProps }>
            query={graphql`
				query NosOffresQuery {
					strapiNosOffres {
						pageTitle
                        pageSubtitle: PageSubtitle

                        imageBlocks {
                            picture {
                                localFile {
									childImageSharp {
										gatsbyImageData
								  	}
								}
                            }
                            title
                            description
                            ctaText
                            ctaLink
                        }

                        cerTitle: title1
                        cerSubtitle: subtitle1
                        certifications {
                            slug
                            CertificationBadge {
                                title
                                subtitle
                                description
                                ctaText
                                ctaLink
                                picture {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData
                                          }
                                    }
                                }
                            }
                        }
					}
				}
				`
            }
            render={data => <Component {...data.strapiNosOffres} />}
        />
    );
};

function NosOffresPage() {
    return NosOffresEnhancer(nousRejoindrePage);
}

const nousRejoindrePage: React.FC<NosOffresProps> = ({
    ref,
    pageTitle,
    pageSubtitle,
    imageBlocks,
    cerTitle,
    cerSubtitle,
    certifications
}) => {
    const [transparentMode, setTransparentMode] = React.useState(
        isBrowser() ? document.body.scrollTop < Math.round(window.innerHeight / 2) : false
    );
    const [currentBgColor, setCurrentBgColor] = React.useState<string>('var(--primary-blue)');
    const [darkMode, setDarkMode] = React.useState(true);

    const banner: BannerProps = {
        title: pageTitle,
        subtitle: pageSubtitle,
    };

    return (
        <PageTopBlock
            ref={ref}
            transparent={transparentMode} dark={darkMode} backgroundColor={currentBgColor}
            setCurrentBgColor={setCurrentBgColor}
            setTransparentMode={(val) => setTransparentMode(val)}
            setDarkMode={setDarkMode}
            banner={banner}
            scrollIntoElementId='offres'
            bannerBackgroundClassName='NosOffres'
        >
            <motion.section
                id='offres'
                className='pt-5'
                viewport={{ amount: 0.60 }}
                onViewportEnter={() => {
                    setCurrentBgColor('var(--white)');
                    setDarkMode(false);
                }}
                style={{ backgroundColor: 'var(--white)' }}
            >
                {imageBlocks && imageBlocks.length > 0 && <OurSkillsBlock skills={imageBlocks} />}
            </motion.section>
            <motion.section
                viewport={{ amount: 0.60 }}
                onViewportEnter={() => {
                    setCurrentBgColor('var(--white)');
                    setDarkMode(false);
                }}
                style={{ backgroundColor: 'var(--white)' }}
            >
                {certifications.length > 0 && (
                    <div className='w-100 px-0 py-5 mx-0 d-flex justify-content-center'>
                        <ExpertiseCertificationBlock
                            title={cerTitle}
                            subtitle={cerSubtitle}
                            certifications={certifications}
                        />
                    </div>
                )}
            </motion.section>
        </PageTopBlock>
    );
};

export default NosOffresPage;

export const Head = () => <DefaultHead {...seo.nosOffres} />;
